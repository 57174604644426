import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

const ThankYou = (lang) => (
  <div className="Container">
    <div className="thankyou">
      <h1>Thank You for contacting us!</h1>
      <h3>We will get back to you within 72 hours.</h3>
      <p>
        <PrismicLink href="/en/contact">
          <button type="button" className="primary-btn">Return to Contact</button>
        </PrismicLink>
      </p>
    </div>
  </div>
)

export default withPrismicUnpublishedPreview(ThankYou)
